<template>
  <div>
    <b-row>
      <b-col
        v-if="bootstraps.length > 0"
        cols="12"
      >
        <b-card
          :title="`Bootstrap (${bootstraps.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>SSID</th>
                  <th>Device</th>
                  <th>Volume</th>
                  <th>AccessInfo</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(bootstrap, index) in bootstraps"
                  :key="`bootstrap-${index}`"
                >
                  <td>{{ bootstrap.date | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ bootstrap.ssid }}</td>
                  <td>{{ bootstrap.device }}</td>
                  <td>{{ bootstrap.volume }}</td>
                  <td>{{ bootstrap.accessInfo }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    bootstraps() {
      if (this.asup.details && this.asup.details.bootstraps) {
        return this.asup.details.bootstraps
          .concat()
          .sort((a, b) => (moment(a.saveTime) > moment(b.saveTime) ? -1 : 1))
      }

      return []
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
